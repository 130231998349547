import { useRoutes } from "react-router-dom";
import userStore, { getUserRole } from "store/user.store";
import { useIsLoggedIn } from "./hooks/useIsLoggedIn";
import routes from "./routing/routes";
import { wrapUseRoutes } from "@sentry/react";
import { useActiveFlowerSystemNotifications } from "api/system-notification/systemNotification.endpoints";
import { maintenanceRoute } from "routing/routes";

const useSentryRoutes = wrapUseRoutes(useRoutes);

function App() {
  const isLoggedIn = useIsLoggedIn();

  const userRole = userStore(getUserRole);

  //Get active Flower system notifications
  const { data: systemNotification, isLoading: isLoadingSystemNotification } =
    useActiveFlowerSystemNotifications();

  // different routes table depending if there is an active FLower maintenance
  // use to block every routes in the app
  const routing = useSentryRoutes(
    systemNotification
      ? maintenanceRoute(systemNotification)
      : routes(isLoggedIn, userRole),
  );

  if (!isLoadingSystemNotification) {
    return <>{routing}</>;
  }

  return null;
}

export default App;
